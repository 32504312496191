@charset "UTF-8";
/*---------------------------------
  Common CSS 2016.11
  CSS Tonooka Document 2016.11
---------------------------------*/
/* 基本設定
-------------------------------------*/
/* webフォント
* 指示がある場合はこの位置で一括インポート
*/
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700,800);
/* 基本設定 */
* {
  box-sizing: border-box; }

body {
  position: relative;
  font-family: "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
  font-weight: 500;
  background: #ffffff;
  color: #000000; }

p {
  text-align: justify;
  font-size: 15px;
  line-height: 30px; }

/* Footer Copyright h2 */
#Copyright h2 {
  display: none; }

/* clearfix */
.clearfix:after {
  content: "";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden; }

/* テスト時のクラス */
.testBorder {
  border: 1px solid #ff0000;
  padding: 1px;
  background-color: #77FFB7; }
  .testBorder * {
    background-color: transparent; }

div#TestDesign {
  position: absolute;
  width: 100%;
  height: 3250px;
  top: 0;
  left: 0;
  z-index: 1000;
  opacity: 0.3;
  background: url(../img/design/01_01_00_00_00_product.jpg) no-repeat center top;
  background-size: 1366px 3200px;
  background: url(../img/design/01_01_00_00_00_product_sp.jpg) no-repeat center top;
  background-size: 375px 3250px; }

/* MainImg共通 */
#PageProduct #MainImg,
#PageColum #MainImg {
  background: url("../img/main_product.jpg") no-repeat scroll center bottom;
  background-size: cover; }

#PageMaker #MainImg {
  background: url("../img/main_maker.jpg") no-repeat scroll center bottom;
  background-size: cover; }
  #PageMaker #MainImg h2 span:nth-of-type(1) {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    letter-spacing: 2px; }

#PageOffice #MainImg {
  background: url("../img/main_office.jpg") no-repeat scroll center bottom;
  background-size: cover; }

#PageContact #MainImg {
  background: url("../img/main_contact.jpg") no-repeat scroll center bottom;
  background-size: cover; }

#PageEnglish #MainImg {
  background: url("../img/main_english.jpg") no-repeat scroll center bottom;
  background-size: cover; }

#PageNews #MainImg {
  background: url("../img/main_news.jpg") no-repeat scroll center bottom;
  background-size: cover; }

#PagePrivacy #MainImg {
  background: url("../img/main_policy.jpg") no-repeat scroll center bottom;
  background-size: cover; }

@media print, screen and (min-width: 768px) {
  /* リンク設定 */
  a:active {
    color: #0067c3;
    text-decoration: none; }
  a:link {
    color: #0067c3;
    text-decoration: none; }
  a:visited {
    color: #0067c3;
    text-decoration: none; }
  a:hover {
    color: #0067c3;
    text-decoration: underline; }
  a img:hover {
    opacity: 0.6; }
  /* SPのみで表示 */
  .dispsp {
    display: none; }
  /* コンテンツ基本幅 */
  .innerBasic {
    width: 1000px;
    margin-right: auto;
    margin-left: auto; }
  /***
    * 基本レイアウト構造
    */
  #Header {
    width: 100%;
    height: 124px;
    background: #101113;
    min-width: 1000px; }
    #Header #ContBoxHeader {
      display: table;
      height: 124px;
      vertical-align: middle; }
      #Header #ContBoxHeader #HeaderLogo {
        display: table-cell;
        width: 430px;
        height: 107px;
        vertical-align: middle; }
        #Header #ContBoxHeader #HeaderLogo > span {
          display: block;
          color: #909090;
          font-size: 13px;
          margin-bottom: 16px;
          letter-spacing: 1px; }
        #Header #ContBoxHeader #HeaderLogo #HeaderLogo a {
          text-decoration: none; }
        #Header #ContBoxHeader #HeaderLogo #HeaderLogo span.catch {
          display: block;
          width: 100%;
          height: 20px;
          line-height: 20px;
          color: #79818d;
          font-size: 13px;
          background-color: #0c1e35;
          font-family: 'Open Sans', sans-serif;
          letter-spacing: 1px; }
        #Header #ContBoxHeader #HeaderLogo h1 {
          width: 355px;
          height: 42px; }
          #Header #ContBoxHeader #HeaderLogo h1 a {
            display: table-cell;
            float: left;
            height: 42px;
            margin: 0 4px 0 0;
            padding: 0;
            width: 106px; }
            #Header #ContBoxHeader #HeaderLogo h1 a img {
              width: 107px;
              height: 42px; }
              #Header #ContBoxHeader #HeaderLogo h1 a img:hover {
                opacity: 1; }
            #Header #ContBoxHeader #HeaderLogo h1 a:hover {
              opacity: 1; }
            #Header #ContBoxHeader #HeaderLogo h1 a + span {
              display: none; }
          #Header #ContBoxHeader #HeaderLogo h1 span.logo {
            display: table;
            width: 360px; }
            #Header #ContBoxHeader #HeaderLogo h1 span.logo span.company {
              display: table-cell;
              padding-left: 7px;
              vertical-align: top;
              width: 300px; }
              #Header #ContBoxHeader #HeaderLogo h1 span.logo span.company span {
                display: block;
                color: #fff;
                width: 240px; }
              #Header #ContBoxHeader #HeaderLogo h1 span.logo span.company span:nth-of-type(1) {
                width: 100%;
                font-size: 10px;
                letter-spacing: 2px;
                margin-bottom: 7px; }
              #Header #ContBoxHeader #HeaderLogo h1 span.logo span.company span:nth-of-type(2) {
                width: 100%;
                color: #fff;
                font-size: 24px; }
      #Header #ContBoxHeader > div:nth-of-type(2) {
        vertical-align: top;
        padding-top: 20px; }
      #Header #ContBoxHeader > div {
        display: table-cell;
        vertical-align: middle; }
        #Header #ContBoxHeader > div nav#HeaderTopMenu {
          margin-bottom: 31px;
          /*googleカスタム検索*/ }
          #Header #ContBoxHeader > div nav#HeaderTopMenu ul {
            display: table;
            margin-left: auto;
            margin-right: 0; }
            #Header #ContBoxHeader > div nav#HeaderTopMenu ul li {
              display: table-cell;
              height: 20px;
              vertical-align: middle;
              color: #fff;
              width: 230px; }
          #Header #ContBoxHeader > div nav#HeaderTopMenu .gsc-control-cse {
            background: transparent;
            border: none;
            padding: 0;
            width: 230px;
            height: 30px;
            position: relative;
            right: 4px; }
          #Header #ContBoxHeader > div nav#HeaderTopMenu .gsc-control-wrapper-cse div {
            border: none; }
          #Header #ContBoxHeader > div nav#HeaderTopMenu .gsc-input {
            padding-right: 0; }
          #Header #ContBoxHeader > div nav#HeaderTopMenu table.gstl_50 {
            background: #101113; }
          #Header #ContBoxHeader > div nav#HeaderTopMenu #gs_tti50.gsib_a {
            padding: 0; }
          #Header #ContBoxHeader > div nav#HeaderTopMenu input.gsc-input {
            width: 230px !important;
            height: 30px !important;
            text-indent: 0 !important;
            padding: 8px 24px 7px 8px !important;
            font-size: 13px;
            color: #c0c0c0;
            background: #343436 !important; }
            #Header #ContBoxHeader > div nav#HeaderTopMenu input.gsc-input:focus {
              border: 1px solid #2a72b4 !important;
              padding-left: 7px !important; }
          #Header #ContBoxHeader > div nav#HeaderTopMenu input.gsc-search-button {
            background: #343436 url("../img/header_icon01.png") no-repeat left center;
            background-size: 18px auto;
            padding: 0 12px;
            height: 28px;
            border: none;
            border-radius: 0;
            position: absolute;
            top: 1px;
            right: 1px;
            margin: 0; }
          #Header #ContBoxHeader > div nav#HeaderTopMenu .gscb_a {
            display: none; }
        #Header #ContBoxHeader > div nav#HeaderMenu {
          width: 573px;
          height: 16px;
          margin-left: auto; }
          #Header #ContBoxHeader > div nav#HeaderMenu > ul#Dropmenu {
            position: relative;
            z-index: 100;
            list-style-type: none;
            width: 573px;
            height: 16px;
            padding: 0;
            color: #fff; }
            #Header #ContBoxHeader > div nav#HeaderMenu > ul#Dropmenu > li {
              height: 16px;
              float: left;
              margin: 0;
              padding: 0; }
              #Header #ContBoxHeader > div nav#HeaderMenu > ul#Dropmenu > li:hover > a {
                opacity: 0.6; }
              #Header #ContBoxHeader > div nav#HeaderMenu > ul#Dropmenu > li:nth-of-type(1) {
                width: 107px; }
              #Header #ContBoxHeader > div nav#HeaderMenu > ul#Dropmenu > li:nth-of-type(2) {
                width: 137px; }
              #Header #ContBoxHeader > div nav#HeaderMenu > ul#Dropmenu > li:nth-of-type(3) {
                width: 108px; }
              #Header #ContBoxHeader > div nav#HeaderMenu > ul#Dropmenu > li:nth-of-type(4) {
                width: 144px; }
              #Header #ContBoxHeader > div nav#HeaderMenu > ul#Dropmenu > li:nth-of-type(5) {
                width: 77px; }
              #Header #ContBoxHeader > div nav#HeaderMenu > ul#Dropmenu > li > a {
                color: #fff;
                font-size: 17px;
                display: block;
                height: 45px;
                text-decoration: none; }
                #Header #ContBoxHeader > div nav#HeaderMenu > ul#Dropmenu > li > a:hover {
                  opacity: 0.6; }
              #Header #ContBoxHeader > div nav#HeaderMenu > ul#Dropmenu > li:nth-of-type(3) ul.child {
                left: 240px; }
                #Header #ContBoxHeader > div nav#HeaderMenu > ul#Dropmenu > li:nth-of-type(3) ul.child li {
                  width: 138px; }
              #Header #ContBoxHeader > div nav#HeaderMenu > ul#Dropmenu > li ul.child {
                display: none;
                list-style: none;
                position: absolute;
                top: 33px;
                left: 0;
                margin: 0; }
                #Header #ContBoxHeader > div nav#HeaderMenu > ul#Dropmenu > li ul.child:before {
                  background: transparent url("../img/header_arrow.png") no-repeat scroll left center/12px auto;
                  content: ".";
                  display: inline-block;
                  text-indent: -9999px;
                  width: 12px;
                  position: relative;
                  left: 30px; }
                #Header #ContBoxHeader > div nav#HeaderMenu > ul#Dropmenu > li ul.child li {
                  display: block;
                  width: 200px;
                  overflow: hidden;
                  background-color: #2a72b4;
                  border-bottom: 1px solid #4c89c1;
                  height: 0;
                  color: #fff; }
                  #Header #ContBoxHeader > div nav#HeaderMenu > ul#Dropmenu > li ul.child li a {
                    display: block;
                    color: #fff;
                    font-size: 15px;
                    padding-left: 17px;
                    height: 49px;
                    background: url("../img/contents/product_arrow01.png") no-repeat right 15px center;
                    background-size: 7px 11px; }
                    #Header #ContBoxHeader > div nav#HeaderMenu > ul#Dropmenu > li ul.child li a:hover {
                      background-color: #2668a4; }
              #Header #ContBoxHeader > div nav#HeaderMenu > ul#Dropmenu > li:hover ul li {
                overflow: visible;
                height: 50px;
                line-height: 50px; }
      #Header #ContBoxHeader #MenuBtn.dispsp {
        display: none; }
  #MainImg {
    min-width: 1000px;
    width: 100%; }
    #MainImg div#MainImgInner {
      margin: 0 auto;
      width: 100%;
      background: url("../img/main_pattern.png") repeat scroll left top;
      height: 400px; }
      #MainImg div#MainImgInner h2 {
        padding-top: 150px;
        text-align: center; }
        #MainImg div#MainImgInner h2 span:nth-of-type(1) {
          color: #fff;
          font-size: 61px; }
        #MainImg div#MainImgInner h2 span:nth-of-type(2) {
          padding-top: 7px;
          color: #cae2ff;
          display: block;
          font-size: 21px;
          letter-spacing: 9px; }
  /* #Container clear fix */
  #Container {
    width: 100%;
    background: #ffffff;
    min-width: 1000px; }
    #Container:after {
      content: "";
      display: block;
      visibility: hidden;
      clear: both;
      height: 0; }
  /*PC #TopicPath  パンくずリスト*/
  #TopicPath {
    /*以下1行削除しないように li display inline-blockカラム落ち対策*/
    font-size: 13px;
    display: block;
    margin: 15px auto;
    width: 1000px;
    padding: 5px 8px 5px 0;
    background: #ffffff;
    text-align: left; }
    #TopicPath li {
      display: inline-block;
      padding-right: 7px; }
      #TopicPath li a {
        display: inline-block; }
        #TopicPath li a:hover {
          text-decoration: underline; }
      #TopicPath li + li {
        position: relative;
        padding-left: 17px;
        color: #888; }
        #TopicPath li + li:before {
          content: "";
          display: inline-block;
          position: absolute;
          top: 0;
          left: 0;
          width: 7px;
          height: 25px;
          background: url("../img/icon_arrow_li.png") no-repeat left top 4px;
          background-size: 4px 6px; }
  /* #Main */
  #Main {
    width: 100%;
    float: left;
    min-height: 100px; }
  /* .sub */
  aside.sub {
    width: 200px;
    float: right; }
    aside.sub ul {
      width: 100%;
      margin: 0 auto;
      border: 1px solid #cccccc; }
      aside.sub ul li {
        display: block;
        width: 100%;
        height: 40px; }
        aside.sub ul li:last-child {
          margin-bottom: 0; }
  /* 見出し */
  h3,
  h4.h3 {
    background: url("../img/h3_bg.jpg") repeat left center;
    margin-bottom: 30px;
    color: #fff;
    padding-top: 18px;
    padding-bottom: 18px;
    width: 100%;
    padding-left: 24px;
    font-size: 23px;
    letter-spacing: 2px; }
  /*PC #Footer上Contact  レイアウト*/
  #FooterContact {
    min-width: 1000px;
    height: 330px;
    background: url("../img/footer_bg.jpg") no-repeat center center;
    background-size: cover; }
    #FooterContact .footerCopy {
      padding-top: 102px;
      font-size: 26px;
      color: #fff;
      text-align: center;
      width: 100%;
      margin-bottom: 28px;
      letter-spacing: 1px; }
    #FooterContact .contactSet {
      height: 84px;
      margin-left: auto;
      margin-right: auto;
      width: 818px;
      overflow: hidden; }
      #FooterContact .contactSet .tel {
        color: #fff;
        text-align: left;
        width: 408px;
        float: left;
        border-right: 2px solid #667383;
        margin-right: 50px;
        padding-top: 15px; }
        #FooterContact .contactSet .tel a {
          color: #fff;
          text-decoration: none; }
        #FooterContact .contactSet .tel span:nth-of-type(1) {
          font-size: 20px; }
        #FooterContact .contactSet .tel span:nth-of-type(2) {
          font-size: 44px;
          font-family: Open Sans, Arial, sans-serif;
          letter-spacing: 3px;
          line-height: 22px; }
        #FooterContact .contactSet .tel span:nth-of-type(3) {
          font-size: 13px;
          display: block;
          letter-spacing: 1px;
          text-align: center;
          padding-right: 60px; }
      #FooterContact .contactSet .mail {
        width: 360px;
        float: left;
        padding-top: 6px; }
        #FooterContact .contactSet .mail a {
          background: #0b64d0 url("../img/footer_icon01.png") no-repeat scroll 51px center;
          background-size: 33px auto;
          color: #fff;
          display: block;
          font-size: 21px;
          height: 70px;
          line-height: 70px;
          padding-left: 100px;
          width: 360px;
          text-decoration: none;
          border: 1px solid #4d8bd8; }
          #FooterContact .contactSet .mail a:hover {
            background-color: #0074ff; }
  /*PC #Footer  フッタ内  レイアウト*/
  #Footer {
    background-color: #242527;
    padding-bottom: 48px;
    padding-top: 50px;
    font-size: 13px;
    min-width: 1000px; }
    #Footer #FooterInfo {
      float: left;
      width: 335px;
      color: #aaa; }
      #Footer #FooterInfo dl dt {
        height: 32px;
        line-height: 32px;
        color: #fff;
        margin-bottom: 10px;
        font-size: 19px; }
        #Footer #FooterInfo dl dt a img {
          margin-right: 12px; }
          #Footer #FooterInfo dl dt a img:hover {
            opacity: 1.0 !important; }
      #Footer #FooterInfo dl dd {
        line-height: 23px;
        letter-spacing: 1.4px; }
        #Footer #FooterInfo dl dd a {
          color: #aaa;
          text-decoration: none;
          margin-right: 10px; }
    #Footer #FooterNav {
      float: right;
      width: 500px;
      text-align: right;
      color: #fff;
      margin-top: 10px; }
      #Footer #FooterNav ul li {
        display: inline-block;
        margin-bottom: 15px; }
        #Footer #FooterNav ul li a {
          color: #fff; }
        #Footer #FooterNav ul li + li {
          padding-left: 28px; }
    #Footer #Copyright {
      font-family: 'Open Sans', sans-serif;
      margin-top: 10px;
      font-size: 10px;
      color: #aaaaaa;
      letter-spacing: 0.8px; }
  /*PC  #PageTop  */
  #PageTop {
    display: none;
    position: fixed;
    z-index: 100;
    bottom: 20px;
    right: 20px;
    text-indent: -9999px; }
    #PageTop a:link,
    #PageTop a:visited {
      display: block;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background: url("../img/pageTop.png");
      background-size: 60px auto; }
    #PageTop a:hover {
      display: block;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background: #ffffff;
      background: url("../img/pageTop.png");
      background-size: 60px auto;
      opacity: .85; }
  #Bottom_nav {
    display: none; }
  #DivProduct_under_nav {
    overflow: hidden; }
    #DivProduct_under_nav ul li {
      position: relative;
      float: left;
      width: 235px;
      margin-right: 20px;
      border: 1px solid #cfd7e8;
      margin-bottom: 20px;
      display: table;
      padding: 10px;
      /*a {
                        font-size: 15px;
                        line-height: 20px;
                        display: table-cell;
                        vertical-align: middle;
                        width: 235px;
                        height: 100px;
                    }*/ }
      #DivProduct_under_nav ul li:hover {
        opacity: 0.6; }
        #DivProduct_under_nav ul li:hover a {
          text-decoration: underline; }
      #DivProduct_under_nav ul li:nth-of-type(4n) {
        margin-right: 0; }
      #DivProduct_under_nav ul li span {
        display: table-cell;
        width: 80px;
        height: 80px;
        vertical-align: middle; }
        #DivProduct_under_nav ul li span img {
          max-width: 100%;
          max-height: 100%;
          width: auto;
          height: auto; }
      #DivProduct_under_nav ul li span + p {
        display: table-cell;
        vertical-align: middle;
        padding-left: 20px;
        line-height: 20px; }
        #DivProduct_under_nav ul li span + p a {
          font-size: 15px;
          line-height: 20px;
          vertical-align: middle; }
      #DivProduct_under_nav ul li img + p {
        float: left;
        margin-left: 20px;
        line-height: 20px;
        padding-right: 10px;
        width: 50%;
        left: 62%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%); } }

@media print, screen and (max-width: 767px) {
  /* リンク設定 */
  a:active {
    color: #0067c3; }
  a:link {
    color: #0067c3; }
  a:visited {
    color: #0067c3; }
  /* 基本設定 */
  .disppc {
    display: none; }
  .innerBasic {
    width: 100%; }
  /*Header*/
  #Header {
    position: relative;
    background-color: #000;
    height: 80px; }
    #Header #ContBoxHeader {
      padding-left: 0; }
      #Header #ContBoxHeader span.catch {
        display: block;
        width: 100%;
        height: 20px;
        background-color: #0c1e35;
        color: #79818d;
        font-family: Open Sans, Arial, sans-serif;
        font-size: 9px;
        line-height: 20px;
        padding-left: 10px;
        letter-spacing: 0.1px; }
      #Header #ContBoxHeader h1 {
        position: relative;
        margin-left: 10px;
        margin-top: 11px;
        display: table;
        width: auto;
        height: 38px; }
        #Header #ContBoxHeader h1 a {
          margin-right: 4px; }
          #Header #ContBoxHeader h1 a img {
            width: 60px;
            height: 23px; }
        #Header #ContBoxHeader h1 span {
          color: #ffffff; }
          #Header #ContBoxHeader h1 span.logo {
            display: block;
            padding-top: 13px;
            padding-left: 0;
            font-size: 19px;
            letter-spacing: -1px; }
          #Header #ContBoxHeader h1 span.company {
            position: absolute;
            top: 0;
            left: 0; }
            #Header #ContBoxHeader h1 span.company span {
              display: block; }
              #Header #ContBoxHeader h1 span.company span:nth-of-type(1) {
                font-size: 10px;
                letter-spacing: 1.3px; }
              #Header #ContBoxHeader h1 span.company span:nth-of-type(2) {
                display: none; }
    #Header #MenuBtn {
      position: absolute;
      z-index: 600;
      top: 20px;
      right: 0;
      width: 60px;
      height: 60px;
      padding-top: 12px;
      background: url(../img/header_menu.png) no-repeat center 13px;
      background-size: 26px auto;
      background-color: #343436;
      color: #c0c0c0;
      font-size: 11px;
      text-align: center;
      line-height: 60px; }
  /* MenuSp GlobalNavSp */
  #MenuSp {
    display: none;
    width: 100%;
    position: absolute;
    top: 70px;
    z-index: 200; }
    #MenuSp #MenuSpMenuList ul li {
      width: 100%;
      background: #252528 url("../img/header_icon_close2.png") no-repeat right 18px center;
      background-size: 16px auto; }
      #MenuSp #MenuSpMenuList ul li a {
        padding-left: 15px;
        display: block;
        color: #fff;
        font-size: 17px;
        text-decoration: none;
        height: 60px;
        line-height: 60px;
        border-bottom: 1px solid #14141a; }
      #MenuSp #MenuSpMenuList ul li.parentAccordion {
        background: #252528 url("../img/header_icon_close.png") no-repeat right 18px center;
        background-size: 16px auto; }
        #MenuSp #MenuSpMenuList ul li.parentAccordion ul.accordion {
          display: none; }
        #MenuSp #MenuSpMenuList ul li.parentAccordion.minus {
          background: #252528 url("../img/header_icon_open.png") no-repeat right 18px center;
          background-size: 16px auto; }
          #MenuSp #MenuSpMenuList ul li.parentAccordion.minus > a {
            background: #252528 url("../img/header_icon_open.png") no-repeat right 18px center;
            background-size: 16px auto; }
      #MenuSp #MenuSpMenuList ul li:nth-of-type(1) {
        /*googleカスタム検索*/ }
        #MenuSp #MenuSpMenuList ul li:nth-of-type(1) .gsc-control-cse {
          padding: 0;
          background: #343436;
          border: none; }
        #MenuSp #MenuSpMenuList ul li:nth-of-type(1) form {
          height: 67px;
          padding: 20px 15px 17px 15px;
          margin: 0;
          position: relative; }
          #MenuSp #MenuSpMenuList ul li:nth-of-type(1) form .gsc-input {
            width: 100%;
            padding: 0; }
          #MenuSp #MenuSpMenuList ul li:nth-of-type(1) form .gsc-input-box {
            border: none;
            background: #343436; }
          #MenuSp #MenuSpMenuList ul li:nth-of-type(1) form #gs_tti51 {
            padding: 0; }
          #MenuSp #MenuSpMenuList ul li:nth-of-type(1) form .gsib_b {
            display: none; }
          #MenuSp #MenuSpMenuList ul li:nth-of-type(1) form input.gsc-input {
            width: 100%;
            height: 30px !important;
            border-bottom: 1px solid #525256 !important;
            background: #343436 !important;
            text-indent: 0 !important;
            padding-right: 24px !important;
            color: #c0c0c0;
            font-size: 15px;
            border-radius: 0 !important; }
          #MenuSp #MenuSpMenuList ul li:nth-of-type(1) form input.gsc-search-button {
            position: absolute;
            top: 18px;
            right: 15px;
            width: 20px;
            height: 30px;
            margin: 0;
            border: none;
            background: #343436 url("../img/header_icon01.png") no-repeat left center;
            background-size: 18px auto;
            padding: 15px 12px; }
    #MenuSp #MenuSpMenuList ul ul.accordion li {
      background: #525257 url("../img/header_icon_close2.png") no-repeat right 21px center;
      background-size: 11px auto;
      background-color: #525257; }
      #MenuSp #MenuSpMenuList ul ul.accordion li a {
        font-size: 15px;
        height: 50px;
        line-height: 50px;
        border-bottom: 1px solid #6d6d72;
        padding-left: 20px; }
      #MenuSp #MenuSpMenuList ul ul.accordion li:last-of-type a {
        border-bottom: none; }
    #MenuSp #MenuSpMenuList #BtnClose {
      text-align: center; }
      #MenuSp #MenuSpMenuList #BtnClose span {
        display: block;
        padding-top: 54px;
        background: url(../img/header_menu_close.png) no-repeat scroll center bottom 18px;
        background-size: 20px auto;
        color: #c0c0c0;
        font-size: 11px; }
  #MenuBack {
    background-color: #000;
    bottom: 0;
    display: none;
    left: 0;
    opacity: 1;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 80px;
    z-index: 100; }
  /*スマホパン屑*/
  #TopicPath {
    margin: 10px 0;
    width: 100%;
    height: 25px;
    padding: 0 15px; }
    #TopicPath ol li {
      display: inline;
      font-size: 11px;
      color: #888; }
      #TopicPath ol li a {
        font-size: 11px;
        line-height: 25px;
        text-decoration: underline;
        color: #239ce7; }
      #TopicPath ol li + li {
        padding-left: 10px;
        position: relative;
        margin-left: 5px; }
      #TopicPath ol li + li:before {
        content: "";
        display: inline-block;
        position: absolute;
        top: 4px;
        left: 0;
        width: 4px;
        height: 6px;
        background: url("../img/icon_arrow_li.png") no-repeat left center;
        background-size: 4px 6px;
        vertical-align: middle; }
  #MainImg {
    width: 100%; }
    #MainImg div#MainImgInner {
      margin: 0 auto;
      width: 100%;
      background: url("../img/main_pattern.png") repeat scroll left top;
      height: 200px; }
      #MainImg div#MainImgInner h2 {
        padding-top: 66px;
        text-align: center; }
        #MainImg div#MainImgInner h2 span:nth-of-type(1) {
          color: #fff;
          font-size: 37px; }
        #MainImg div#MainImgInner h2 span:nth-of-type(2) {
          display: block;
          padding-top: 11px;
          color: #cae2ff;
          font-size: 17px;
          letter-spacing: 6px; }
  /*Main*/
  #Main section#ContBox01 {
    min-height: 100px; }
  /* 見出し */
  h3,
  h4.h3 {
    width: 100%;
    padding-top: 17px;
    padding-bottom: 17px;
    padding-left: 10px;
    letter-spacing: 2px;
    background: url(../img/h3_bg.jpg) repeat left center;
    color: #fff;
    font-size: 23px;
    margin-bottom: 23px;
    line-height: 27px; }
  /*aside.sub*/
  aside.sub {
    width: 100%;
    margin-top: 10px; }
    aside.sub h3 {
      height: 40px;
      padding-left: 10px;
      line-height: 40px;
      font-size: 14px;
      background: #EF858C;
      color: #fff;
      text-align: center; }
    aside.sub ul li a {
      display: block;
      padding-left: 10px;
      line-height: 40px;
      width: 100%;
      height: 40px;
      color: #333; }
  /*アコーディオン見出し*/
  #PageProduct.pageEntry #Container #Main #ContBox01 div.accordion {
    display: none; }
  #PageProduct.pageEntry #Container #Main #ContBox01 h5 {
    background: url("../img/contents/product_nav_open.png") no-repeat right 5% center;
    background-size: 16px 16px;
    padding: 25px 50px 25px 15px;
    border-top: 1px solid #e3e3e3;
    color: #0067c3;
    font-size: 19px;
    line-height: 30px; }
  #PageProduct.pageEntry #Container #Main #ContBox01 .parentAccordion.minus h5 {
    background: url("../img/contents/product_nav_close.png") no-repeat right 5% center;
    background-size: 16px 16px;
    color: #000;
    padding-bottom: 15px; }
  /*#Footer上Contact  レイアウト*/
  #FooterContact {
    width: 100%;
    height: 330px;
    background: url("../img/footer_bg_sp.jpg") no-repeat center center;
    background-size: cover; }
    #FooterContact .footerCopy {
      padding-top: 44px;
      font-size: 20px;
      color: #fff;
      text-align: center;
      width: 100%;
      margin-bottom: 25px;
      line-height: 30px;
      padding-left: 14px;
      letter-spacing: 1px; }
      #FooterContact .footerCopy:after {
        content: "";
        display: block;
        width: 142px;
        margin: 13px auto;
        border-bottom: 2px solid #6a7683; }
    #FooterContact .contactSet {
      margin-left: auto;
      margin-right: auto;
      width: 280px; }
      #FooterContact .contactSet .tel {
        color: #fff;
        width: 100%;
        margin-bottom: 10px; }
        #FooterContact .contactSet .tel a {
          color: #fff; }
        #FooterContact .contactSet .tel span:nth-of-type(1) {
          font-size: 18px;
          width: 46px;
          display: inline-block; }
        #FooterContact .contactSet .tel span:nth-of-type(2) {
          font-size: 33px;
          font-family: Open Sans, Arial, sans-serif;
          letter-spacing: 2px;
          width: 234px;
          display: inline-block;
          text-decoration: underline;
          margin-bottom: 5px; }
        #FooterContact .contactSet .tel span:nth-of-type(3) {
          font-size: 13px;
          display: block;
          letter-spacing: 1px;
          text-align: center; }
      #FooterContact .contactSet .mail {
        width: 100%; }
        #FooterContact .contactSet .mail a {
          background: #0b64d0 url("../img/footer_icon01.png") no-repeat scroll 12% center;
          background-size: 25px auto;
          color: #fff;
          display: block;
          font-size: 18px;
          height: 60px;
          line-height: 60px;
          width: 100%;
          padding-left: 25%;
          text-decoration: none;
          border: 1px solid #4d8bd8; }
  /*Footer*/
  #Footer {
    position: relative;
    width: 100%;
    background-color: #242527;
    color: #aaa;
    font-size: 13px;
    padding: 50px 15px 125px; }
    #Footer #FooterInfo {
      margin-bottom: 22px; }
      #Footer #FooterInfo dt {
        margin-bottom: 11px;
        height: 32px;
        line-height: 33px;
        color: #fff;
        font-size: 19px; }
        #Footer #FooterInfo dt a img {
          margin-right: 12px; }
          #Footer #FooterInfo dt a img:hover {
            opacity: 1.0 !important; }
      #Footer #FooterInfo dd {
        line-height: 24px;
        letter-spacing: 1.4px; }
        #Footer #FooterInfo dd a {
          color: #aaa;
          text-decoration: none;
          margin-right: 10px; }
    #Footer #FooterNav ul li {
      display: inline-block;
      width: 49%;
      margin-bottom: 22px; }
      #Footer #FooterNav ul li a {
        font-size: 13px;
        color: #fff; }
    #Footer #Copyright {
      margin-top: 10px;
      font-family: 'Open Sans', sans-serif;
      font-size: 10px;
      color: #aaaaaa;
      letter-spacing: 0.7px; }
  /*sp  #PageTop  */
  #PageTop {
    position: absolute;
    right: 13px;
    bottom: 94px;
    text-indent: -9999px; }
    #PageTop a:link,
    #PageTop a:visited {
      display: block;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background: url("../img/pageTop.png") no-repeat center center;
      background-size: 100% 100%; }
  /*sp  #Bottom_nav  */
  #DivBottom_nav {
    height: 70px;
    bottom: 0;
    display: table;
    left: 0;
    position: fixed;
    width: 100%;
    z-index: 3; }
    #DivBottom_nav #Bottom_nav ul {
      height: 70px;
      line-height: 70px;
      background-color: #343436; }
      #DivBottom_nav #Bottom_nav ul li {
        display: inline-block;
        width: 24%;
        height: 70px;
        text-align: center;
        line-height: 70px; }
        #DivBottom_nav #Bottom_nav ul li a {
          display: block;
          height: 70px;
          padding-top: 18px;
          font-size: 11px;
          color: #c0c0c0;
          text-decoration: none; }
        #DivBottom_nav #Bottom_nav ul li:nth-of-type(1) {
          background: url("../img/contents/under_nav_icon01.png") no-repeat center top 14px;
          background-size: 27px auto; }
        #DivBottom_nav #Bottom_nav ul li:nth-of-type(2) {
          background: url("../img/contents/under_nav_icon02.png") no-repeat center top 14px;
          background-size: 25px auto; }
        #DivBottom_nav #Bottom_nav ul li:nth-of-type(3) {
          background: url("../img/contents/under_nav_icon03.png") no-repeat center top 14px;
          background-size: 22px auto; }
        #DivBottom_nav #Bottom_nav ul li:nth-of-type(4) {
          background: url("../img/contents/under_nav_icon04.png") no-repeat center top 14px;
          background-size: 24px auto; }
  #DivProduct_under_nav ul {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px; }
    #DivProduct_under_nav ul li {
      position: relative;
      width: 100%;
      padding: 5px;
      margin-bottom: 9px;
      border: 1px solid #cfd7e8;
      display: table;
      /*img {
                        float: left;
                        width: 60px;
                        height: 60px;
                        margin-left: 5px;
                    }
                    img+p {
                        float: left;
                        line-height: 20px;
                        left: 152px;
                        position: absolute;
                        top: 50%;
                        transform: translate(-50%, -50%);
                    }
                    a {
                        font-size: 15px;
                        line-height: 20px;
                        display: table-cell;
                        vertical-align: middle;
                        width: 100%;
                        height: 71px;
                        text-decoration: none;
                    }*/ }
      #DivProduct_under_nav ul li:nth-of-type(4n) {
        margin-right: 0; }
      #DivProduct_under_nav ul li span {
        display: table-cell;
        width: 60px;
        height: 60px;
        vertical-align: middle; }
        #DivProduct_under_nav ul li span img {
          max-width: 100%;
          max-height: 100%;
          width: auto;
          height: auto; }
      #DivProduct_under_nav ul li span + p {
        display: table-cell;
        vertical-align: middle;
        padding-left: 20px;
        line-height: 20px; }
        #DivProduct_under_nav ul li span + p a {
          font-size: 15px;
          line-height: 20px;
          vertical-align: middle;
          text-decoration: none; } }
