@charset "utf-8";


//*basicInner*/
//コンテンツ幅
$pc_basicInner: 1000px;

//*aside#Sub*/
//Subサイドバーの大きさ。
$pc_Sub_width:200px;

//SubサイドバーとMainコンテンツとの空き
$pc_Sub_margin_from_Sub_to_Main:50px;


///**********************************************************
//* 以下は色パターン情報 「基本サイト設計情報.xlsm」参考
//* 使いたいブロックを最後尾上書き
//***********************************************************/

////*リンク基本色 #F39800*/
$color_basic1:#ffffff;
$color_basic2:#F2E9DA;
$color_border1:#cccccc;
$color_font:#333333;
$color_link1:#777777;
$color_link2:#239ce7;
$color_header_bg:#efefef;
$color_globalnav_border:#F39800;
$color_globalnav_li_bg:#F2E9DA;
$color_topicpath_font:#cccccc;
$color_topicpath_font_link_:#239ce7;
$color_h2_basic:#F39800;
$color_h3_basic:#F39800;
$color_header_footer:#efefef;
$color_header_footer_border:#cccccc;
$color_copyright_footer_bg:#666666;
$color_button:#00F299;
$color_caution_bg:#F22000;
$color_caution_font:#ffffff;

////*リンク基本色 #004986*/
$color_basic1:#ffffff;
$color_basic2:#EBF2F7;
$color_border1:#cccccc;
$color_font:#333333;
$color_link1:#777777;
$color_link2:#239ce7;
$color_header_bg:#efefef;
$color_globalnav_border:#0097DB;
$color_globalnav_li_bg:#ffffff;
$color_topicpath_font:#cccccc;
$color_topicpath_font_link_:#239ce7;
$color_h2_basic:#004986;
$color_h3_basic:#004986;
$color_header_footer:#efefef;
$color_header_footer_border:#cccccc;
$color_copyright_footer_bg:#666666;
$color_button:#078700;
$color_caution_bg:#870007;
$color_caution_font:#ffffff;

////*リンク基本色 #E60012*/
$color_basic1:#ffffff;
$color_basic2:#E6CFD0;
$color_border1:#cccccc;
$color_font:#333333;
$color_link1:#777777;
$color_link2:#239ce7;
$color_header_bg:#efefef;
$color_globalnav_border:#E60012;
$color_globalnav_li_bg:#E6CFD0;
$color_topicpath_font:#cccccc;
$color_topicpath_font_link_:#239ce7;
$color_h2_basic:#E60012;
$color_h3_basic:#E60012;
$color_header_footer:#efefef;
$color_header_footer_border:#cccccc;
$color_copyright_footer_bg:#666666;
$color_button:#0086E6;
$color_caution_bg:#E66000;
$color_caution_font:#ffffff;

////*リンク基本色 #009944*/
$color_basic1:#ffffff;
$color_basic2:#E5F0EA;
$color_border1:#cccccc;
$color_font:#333333;
$color_link1:#777777;
$color_link2:#239ce7;
$color_header_bg:#efefef;
$color_globalnav_border:#009944;
$color_globalnav_li_bg:#E5F0EA;
$color_topicpath_font:#cccccc;
$color_topicpath_font_link_:#239ce7;
$color_h2_basic:#009944;
$color_h3_basic:#009944;
$color_header_footer:#efefef;
$color_header_footer_border:#cccccc;
$color_copyright_footer_bg:#666666;
$color_button:#F39800;
$color_caution_bg:#990008;
$color_caution_font:#ffffff;

////*リンク基本色 #00A0E9*/
$color_basic1:#ffffff;
$color_basic2:#EEF4F6;
$color_border1:#cccccc;
$color_font:#333333;
$color_link1:#777777;
$color_link2:#239ce7;
$color_header_bg:#efefef;
$color_globalnav_border:#00A0E9;
$color_globalnav_li_bg:#EEF4F6;
$color_topicpath_font:#cccccc;
$color_topicpath_font_link_:#239ce7;
$color_h2_basic:#00A0E9;
$color_h3_basic:#00A0E9;
$color_header_footer:#efefef;
$color_header_footer_border:#cccccc;
$color_copyright_footer_bg:#666666;
$color_button:#E84900;
$color_caution_bg:#E8002B;
$color_caution_font:#ffffff;

////*リンク基本色 #C1DB81*/
$color_basic1:#ffffff;
$color_basic2:#ECF2DF;
$color_border1:#cccccc;
$color_font:#333333;
$color_link1:#777777;
$color_link2:#239ce7;
$color_header_bg:#efefef;
$color_globalnav_border:#C1DB81;
$color_globalnav_li_bg:#ECF2DF;
$color_topicpath_font:#cccccc;
$color_topicpath_font_link_:#239ce7;
$color_h2_basic:#C1DB81;
$color_h3_basic:#C1DB81;
$color_header_footer:#efefef;
$color_header_footer_border:#cccccc;
$color_copyright_footer_bg:#666666;
$color_button:#8195DB;
$color_caution_bg:#DB8195;
$color_caution_font:#ffffff;

////*リンク基本色 #EE87B4*/
$color_basic1:#ffffff;
$color_basic2:#FAF4F7;
$color_border1:#cccccc;
$color_font:#333333;
$color_link1:#777777;
$color_link2:#239ce7;
$color_header_bg:#efefef;
$color_globalnav_border:#EE87B4;
$color_globalnav_li_bg:#FAF4F7;
$color_topicpath_font:#cccccc;
$color_topicpath_font_link_:#239ce7;
$color_h2_basic:#EE87B4;
$color_h3_basic:#EE87B4;
$color_header_footer:#efefef;
$color_header_footer_border:#cccccc;
$color_copyright_footer_bg:#666666;
$color_button:#87EDC1;
$color_caution_bg:#ED8E87;
$color_caution_font:#ffffff;

////*リンク基本色 #F5B090*/
$color_basic1:#ffffff;
$color_basic2:#F9F3F0;
$color_border1:#cccccc;
$color_font:#333333;
$color_link1:#777777;
$color_link2:#239ce7;
$color_header_bg:#efefef;
$color_globalnav_border:#F5B090;
$color_globalnav_li_bg:#F9F3F0;
$color_topicpath_font:#cccccc;
$color_topicpath_font_link_:#239ce7;
$color_h2_basic:#F5B090;
$color_h3_basic:#F5B090;
$color_header_footer:#efefef;
$color_header_footer_border:#cccccc;
$color_copyright_footer_bg:#666666;
$color_button:#A3F590;
$color_caution_bg:#F54E00;
$color_caution_font:#ffffff;

////*リンク基本色 #A7381D*/
$color_basic1:#ffffff;
$color_basic2:#F8F1EF;
$color_border1:#cccccc;
$color_font:#333333;
$color_link1:#777777;
$color_link2:#239ce7;
$color_header_bg:#efefef;
$color_globalnav_border:#A7381D;
$color_globalnav_li_bg:#F8F1EF;
$color_topicpath_font:#cccccc;
$color_topicpath_font_link_:#239ce7;
$color_h2_basic:#A7381D;
$color_h3_basic:#A7381D;
$color_header_footer:#efefef;
$color_header_footer_border:#cccccc;
$color_copyright_footer_bg:#666666;
$color_button:#45A61C;
$color_caution_bg:#A61C45;
$color_caution_font:#ffffff;

//////*リンク基本色 #EF858C*/
$color_basic1:#ffffff;
$color_basic2:#FDF9FA;
$color_border1:#cccccc;
$color_font:#333333;
$color_link1:#777777;
$color_link2:#239ce7;
$color_header_bg:#efefef;
$color_globalnav_border:#EF858C;
$color_globalnav_li_bg:#FDF9FA;
$color_topicpath_font:#cccccc;
$color_topicpath_font_link_:#239ce7;
$color_h2_basic:#EF858C;
$color_h3_basic:#EF858C;
$color_header_footer:#efefef;
$color_header_footer_border:#cccccc;
$color_copyright_footer_bg:#666666;
$color_button:#86F0E9;
$color_caution_bg:#F04853;
$color_caution_font:#ffffff;