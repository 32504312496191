@import "var.conf";

/*---------------------------------
  Common CSS 2016.11
  CSS Tonooka Document 2016.11
---------------------------------*/


/* 基本設定
-------------------------------------*/


/* webフォント
* 指示がある場合はこの位置で一括インポート
*/

@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700,800);

/* 基本設定 */

* {
    //padding, borderによる領域崩壊回避
    box-sizing: border-box;
}

body {
    position:relative;
    //ベースフォント
    font-family: "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
    font-weight: 500;
    //ベース背景色
    background: $color_basic1;
    //ベースフォント色
    color: #000000;
}

p {
    text-align: justify;
    font-size: 15px;
    line-height: 30px;
}


/* Footer Copyright h2 */

#Copyright h2 {
    // 以下は固定
    display: none;
}


/* clearfix */

.clearfix:after {
    content: "";
    display: block;
    clear: both;
    height: 0;
    visibility: hidden;
}


/* テスト時のクラス */

.testBorder {
    border: 1px solid #ff0000;
    padding: 1px;
    background-color: #77FFB7;
    * {
        background-color: transparent;
    }
}

div#TestDesign {
    position: absolute;
    width: 100%;
    height: 3250px;
    top: 0;
    left: 0;
    z-index: 1000;
    opacity: 0.3;
    background: url(../img/design/01_01_00_00_00_product.jpg) no-repeat center top;
    background-size: 1366px 3200px;
    background: url(../img/design/01_01_00_00_00_product_sp.jpg) no-repeat center top;
    background-size: 375px 3250px;
}


/* MainImg共通 */

#PageProduct #MainImg,
#PageColum #MainImg {
    background: url("../img/main_product.jpg") no-repeat scroll center bottom;
    background-size: cover;
}

#PageMaker #MainImg {
    background: url("../img/main_maker.jpg") no-repeat scroll center bottom;
    background-size: cover;
    h2 {
        span:nth-of-type(1) {
            font-family: 'Open Sans', sans-serif;
            font-weight: 400;
            letter-spacing: 2px;
        }
    }
}

#PageOffice #MainImg {
    background: url("../img/main_office.jpg") no-repeat scroll center bottom;
    background-size: cover;
}

#PageContact #MainImg {
    background: url("../img/main_contact.jpg") no-repeat scroll center bottom;
    background-size: cover;
}

#PageEnglish #MainImg {
    background: url("../img/main_english.jpg") no-repeat scroll center bottom;
    background-size: cover;
}

#PageNews #MainImg {
    background: url("../img/main_news.jpg") no-repeat scroll center bottom;
    background-size: cover;
}

#PagePrivacy #MainImg {
    background: url("../img/main_policy.jpg") no-repeat scroll center bottom;
    background-size: cover;
}

@media print,
screen and (min-width: 768px) {
    /* リンク設定 */
    a {
        &:active {
            color: #0067c3;
            text-decoration: none;
        }
        &:link {
            color: #0067c3;
            text-decoration: none;
            // transition: all 0.5s ease;
        }
        &:visited {
            color: #0067c3;
            text-decoration: none;
        }
        &:hover {
            color: #0067c3;
            text-decoration: underline;
            // opacity: 0.6;
        }
    }
    a img:hover {
        opacity: 0.6;
    }
    /* SPのみで表示 */
    .dispsp {
        display: none;
    }
    /* コンテンツ基本幅 */
    .innerBasic {
        width: $pc_basicInner; //var.confで定義
        margin-right: auto; //センター寄せ
        margin-left: auto;
    }
    /***
    * 基本レイアウト構造
    */
    #Header {
        width: 100%;
        height: 124px;
        background: #101113;
        min-width: 1000px;
        #ContBoxHeader {
            display: table;
            height: 124px;
            vertical-align: middle;
            #HeaderLogo {
                display: table-cell;
                width: 430px;
                height: 107px;
                vertical-align: middle;
                >span {
                    display: block;
                    color: #909090;
                    font-size: 13px;
                    margin-bottom: 16px;
                    letter-spacing: 1px;
                }
                #HeaderLogo {
                    a {
                        text-decoration: none;
                    }
                    span.catch {
                        display: block;
                        width: 100%;
                        height: 20px;
                        line-height: 20px;
                        color: #79818d;
                        font-size: 13px;
                        background-color: #0c1e35;
                        font-family: 'Open Sans', sans-serif;
                        letter-spacing: 1px;
                    }
                }
                h1 {
                    width: 355px;
                    height: 42px;
                    a {
                        display: table-cell;
                        float: left;
                        height: 42px;
                        margin: 0 4px 0 0;
                        padding: 0;
                        width: 106px;
                        img {
                            width: 107px;
                            height: 42px;
                            &:hover{
                                opacity:1;
                            }
                        }
                        &:hover {
                            opacity: 1;
                        }
                        &+span {
                            display: none;
                        }
                    }
                    span {
                        &.logo {
                            display: table;
                            width: 360px;
                            span.company {
                                display: table-cell;
                                padding-left: 7px;
                                vertical-align: top;
                                width: 300px;
                                span {
                                    display: block;
                                    color: #fff;
                                    width: 240px;
                                }
                                span:nth-of-type(1) {
                                    width: 100%;
                                    font-size: 10px;
                                    letter-spacing: 2px;
                                    margin-bottom: 7px;
                                }
                                span:nth-of-type(2) {
                                    width: 100%;
                                    color: #fff;
                                    font-size: 24px;
                                }
                            }
                        }
                    }
                }
            }
            >div:nth-of-type(2) {
                vertical-align: top;
                padding-top: 20px;
            }
            >div {
                display: table-cell;
                vertical-align: middle;
                nav#HeaderTopMenu {
                    margin-bottom: 31px;
                    // margin-bottom: 28px;
                    ul {
                        display: table;
                        margin-left: auto;
                        margin-right: 0;
                        li {
                            display: table-cell;
                            height: 20px;
                            vertical-align: middle;
                            color: #fff;
                            width: 230px;
                        }
                    }
                    /*googleカスタム検索*/
                    .gsc-control-cse{
                        background:transparent;
                        border:none;
                        padding:0;
                        width:230px;
                        height:30px;
                        position:relative;
                        right:4px;
                    }
                    .gsc-control-wrapper-cse div{
                        //height:30px;
                        border:none;
                    }
                    .gsc-input{
                        padding-right:0;
                    }
                    table.gstl_50 {
                        background:#101113;
                    }
                    #gs_tti50.gsib_a{
                        padding:0;
                    }
                    input.gsc-input{
                        width:230px!important;
                        height:30px!important;
                        text-indent:0!important;
                        padding:8px 24px 7px 8px!important;
                        font-size:13px;
                        color:#c0c0c0;
                        background: #343436 !important;
                        //background-color:#343436!important;
                        &:focus {
                            border: 1px solid #2a72b4!important;
                            padding-left:7px!important;
                        }
                    }
                    input.gsc-search-button{
                        background: #343436 url("../img/header_icon01.png") no-repeat left center;
                        background-size: 18px auto;
                        padding:0 12px;
                        height:28px;
                        border:none;
                        border-radius:0;
                        position:absolute;
                        top:1px;
                        right:1px;
                        margin:0;
                    }
                    .gscb_a{
                        display:none;
                    }
                    // .cse .gsc-control-cse,
                    // .gsc-control-cse {
                    //     border: none;
                    //     background-color: #343436;
                    // }
                    // table.gsc-search-box * {
                    //     background-color: #343436 !important;
                    //     border: none;
                    // }
                    // #Header #ContBoxHeader > div nav#HeaderTopMenu .cse .gsc-control-cse,
                    // #Header #ContBoxHeader > div nav#HeaderTopMenu .gsc-control-cse {
                    //     background-color: #101113;
                    // }
                    // div.gsc-control-cse {
                    //     padding: 0 !important;
                    // }
                    // td.gsc-search-button{
                    //     width:15px;
                    // }
                    // input {
                    //     background: #343436 url("../img/header_icon01.png") no-repeat scroll right 10px center / 15px auto;
                    //     border: medium none;
                    //     color: #c0c0c0;
                    //     font-size: 13px;
                    //     width:15px;
                    //     height: 15px;
                    //     outline: 0 none;
                    //     padding-left: 10px;
                    //     padding-right: 10px;
                    //     background-color: #343436 !important;
                    //     &:focus {
                    //         background: #343436 url("../img/header_icon01.png") no-repeat right 10px center;
                    //         background-size: 15px auto;
                    //         border: 1px solid #2a72b4;
                    //     }
                    // }
                    //input {
                    //    background: #343436 url("../img/header_icon01.png") no-repeat right 15px center;
                    //    background-size: 18px auto;
                    //    color: #c0c0c0;
                    //    outline: 0 none;
                    //    padding-left: 10px;
                    //    padding-right: 10px;
                    //    width: 230px;
                    //    height: 30px;
                    //    line-height: 30px;
                    //    font-size: 13px;
                    //    &:focus {
                    //        background: #343436 url("../img/header_icon01.png") no-repeat right 14px center;
                    //        background-size: 18px auto;
                    //        border: 1px solid #2a72b4;
                    //    }
                    //    &.css-button {
                    //        width: 50px;
                    //    }
                    //}
                }
                nav#HeaderMenu {
                    width: 573px;
                    height: 16px;
                    margin-left: auto;
                    >ul#Dropmenu {
                        position: relative;
                        z-index: 100;
                        list-style-type: none;
                        width: 573px;
                        height: 16px;
                        padding: 0;
                        color: #fff;
                        >li {
                            height: 16px;
                            float: left;
                            margin: 0;
                            padding: 0;
                            &:hover {
                                >a {
                                    opacity: 0.6;
                                }
                            }
                            &:nth-of-type(1) {
                                width: 107px;
                            }
                            &:nth-of-type(2) {
                                width: 137px;
                            }
                            &:nth-of-type(3) {
                                width: 108px;
                            }
                            &:nth-of-type(4) {
                                width: 144px;
                            }
                            &:nth-of-type(5) {
                                width: 77px;
                            }
                            >a {
                                color: #fff;
                                font-size: 17px;
                                display: block;
                                height: 45px;
                                text-decoration: none;
                                &:hover {
                                    opacity: 0.6;
                                }
                            }
                            &:nth-of-type(3) ul.child {
                                left: 240px;
                                li {
                                    width: 138px;
                                }
                            }
                            ul.child {
                                display: none;
                                list-style: none;
                                position: absolute;
                                top: 33px;
                                left: 0;
                                margin: 0;
                                //padding-top: 46px;
                                &:before {
                                    background: transparent url("../img/header_arrow.png") no-repeat scroll left center / 12px auto;
                                    content: ".";
                                    display: inline-block;
                                    text-indent: -9999px;
                                    width: 12px;
                                    position: relative;
                                    left: 30px;
                                }
                                li {
                                    display: block;
                                    width: 200px;
                                    overflow: hidden;
                                    background-color: #2a72b4;
                                    border-bottom: 1px solid #4c89c1;
                                    height: 0;
                                    color: #fff;
                                    a {
                                        display: block;
                                        color: #fff;
                                        font-size: 15px;
                                        padding-left: 17px;
                                        height: 49px;
                                        background: url("../img/contents/product_arrow01.png") no-repeat right 15px center;
                                        background-size: 7px 11px;
                                        &:hover {
                                            background-color: #2668a4;
                                        }
                                    }
                                }
                            }
                            &:hover {
                                ul {
                                    li {
                                        overflow: visible;
                                        height: 50px;
                                        line-height: 50px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            #MenuBtn.dispsp {
                display: none;
            }
        }
    }
    #MainImg {
        min-width:1000px;
        width: 100%;
        div#MainImgInner {
            margin: 0 auto;
            width: 100%;
            background: url("../img/main_pattern.png") repeat scroll left top;
            height: 400px;
            h2 {
                padding-top: 150px;
                text-align: center;
                span:nth-of-type(1) {
                    color: #fff;
                    font-size: 61px;
                }
                span:nth-of-type(2) {
                    padding-top: 7px;
                    color: #cae2ff;
                    display: block;
                    font-size: 21px;
                    letter-spacing: 9px;
                }
            }
        }
    }
    /* #Container clear fix */
    #Container {
        width: 100%;
        background: $color_basic1;
        min-width: 1000px;
        &:after {
            content: "";
            display: block;
            visibility: hidden;
            clear: both;
            height: 0;
        }
    }
    /*PC #TopicPath  パンくずリスト*/
    #TopicPath {
        /*以下1行削除しないように li display inline-blockカラム落ち対策*/
        font-size: 13px;
        display: block;
        margin: 15px auto;
        width: $pc_basicInner;
        padding: 5px 8px 5px 0;
        background: $color_basic1;
        text-align: left;
        li {
            display: inline-block;
            padding-right: 7px;
            a {
                display: inline-block;
                &:active,
                &:link,
                &:visited {}
                &:hover {
                    text-decoration: underline;
                }
            }
            // 2つめ以降のパンくずリストの前置記号
            &+li {
                position: relative;
                padding-left: 17px;
                color: #888;
                &:before {
                    content: "";
                    display: inline-block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 7px;
                    height: 25px;
                    background: url("../img/icon_arrow_li.png") no-repeat left top 4px;
                    background-size: 4px 6px;
                }
            }
        }
    }
    /* #Main */
    #Main {
        width: 100%;
        float: left;
        min-height: 100px;
    }
    /* .sub */
    aside.sub {
        width: $pc_Sub_width;
        float: right;
        ul {
            width: 100%;
            margin: 0 auto;
            border: 1px solid $color_border1;
            li {
                display: block;
                width: 100%;
                height: 40px;
                &:last-child {
                    margin-bottom: 0;
                }
                a {
                    &:visited {}
                }
            }
        }
    }
    /* 見出し */
    h3,
    h4.h3 {
        background: url("../img/h3_bg.jpg") repeat left center;
        margin-bottom: 30px;
        color: #fff;
        padding-top: 18px;
        padding-bottom: 18px;
        width: 100%;
        padding-left: 24px;
        font-size: 23px;
        letter-spacing: 2px;
    }
    /*PC #Footer上Contact  レイアウト*/
    #FooterContact {
        min-width:1000px;
        height: 330px;
        background: url("../img/footer_bg.jpg") no-repeat center center;
        background-size: cover;
        .footerCopy {
            padding-top: 102px;
            font-size: 26px;
            color: #fff;
            text-align: center;
            width: 100%;
            margin-bottom: 28px;
            letter-spacing: 1px;
        }
        .contactSet {
            height: 84px;
            margin-left: auto;
            margin-right: auto;
            width: 818px;
            overflow: hidden;
            .tel {
                color: #fff;
                text-align: left;
                width: 408px;
                float: left;
                border-right: 2px solid #667383;
                margin-right: 50px;
                padding-top: 15px;
                a {
                    color: #fff;
                    text-decoration: none;
                }
                span:nth-of-type(1) {
                    font-size: 20px;
                }
                span:nth-of-type(2) {
                    font-size: 44px;
                    font-family: Open Sans, Arial, sans-serif;
                    letter-spacing: 3px;
                    line-height: 22px;
                }
                span:nth-of-type(3) {
                    font-size: 13px;
                    display: block;
                    letter-spacing: 1px;
                    text-align: center;
                    padding-right: 60px;
                }
            }
            .mail {
                width: 360px;
                float: left;
                padding-top: 6px;
                a {
                    background: #0b64d0 url("../img/footer_icon01.png") no-repeat scroll 51px center;
                    background-size: 33px auto;
                    color: #fff;
                    display: block;
                    font-size: 21px;
                    height: 70px;
                    line-height: 70px;
                    padding-left: 100px;
                    width: 360px;
                    text-decoration: none;
                    border: 1px solid #4d8bd8;
                    &:hover {
                        background-color: #0074ff;
                    }
                }
            }
        }
    }
    /*PC #Footer  フッタ内  レイアウト*/
    #Footer {
        background-color: #242527;
        padding-bottom: 48px;
        padding-top: 50px;
        font-size: 13px;
        min-width: 1000px;
        #FooterInfo {
            float: left;
            width: 335px;
            color: #aaa;
            dl {
                dt {
                    height: 32px;
                    line-height: 32px;
                    color: #fff;
                    margin-bottom: 10px;
                    font-size: 19px;
                    a img {
                        margin-right: 12px;
                        &:hover {
                            opacity: 1.0 !important;
                        }
                    }
                }
                dd {
                    line-height: 23px;
                    letter-spacing: 1.4px;
                    a {
                        color: #aaa;
                        text-decoration: none;
                        margin-right: 10px;
                    }
                }
            }
        }
        #FooterNav {
            float: right;
            width: 500px;
            text-align: right;
            color: #fff;
            margin-top: 10px;
            ul {
                li {
                    display: inline-block;
                    margin-bottom: 15px;
                    a {
                        color: #fff;
                    }
                    &+li {
                        padding-left: 28px;
                    }
                }
            }
        }
        #Copyright {
            font-family: 'Open Sans', sans-serif;
            margin-top: 10px;
            font-size: 10px;
            color: #aaaaaa;
            letter-spacing: 0.8px;
        }
    }
    /*PC  #PageTop  */
    #PageTop {
        display: none;
        position: fixed;
        z-index: 100;
        bottom: 20px;
        right: 20px;
        text-indent: -9999px;
        a:link,
        a:visited {
            display: block;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background: url("../img/pageTop.png");
            background-size: 60px auto;
        }
        a:hover {
            display: block;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background: $color_basic1;
            background: url("../img/pageTop.png");
            background-size: 60px auto;
            opacity: .85;
        }
    }
    #Bottom_nav {
        display: none;
    }
    #DivProduct_under_nav {
        overflow: hidden;
        ul {
            li {
                position: relative;
                float: left;
                width: 235px;
                //height: 100px;
                margin-right: 20px;
                border: 1px solid #cfd7e8;
                margin-bottom: 20px;
                display: table;
                padding: 10px;
                &:hover {
                    opacity: 0.6;
                    a {
                        text-decoration: underline;
                    }
                }
                &:nth-of-type(4n) {
                    margin-right: 0;
                }
                span {
                    display: table-cell;
                    width: 80px;
                    height: 80px;
                    vertical-align: middle;
                    img {
                        max-width: 100%;
                        max-height: 100%;
                        width: auto;
                        height: auto;
                    }
                }
                span+p {
                    display: table-cell;
                    vertical-align: middle;
                    padding-left: 20px;
                    line-height: 20px;
                    a {
                        font-size: 15px;
                        line-height: 20px;
                        vertical-align: middle;
                    }
                }
                img+p {
                    float: left;
                    margin-left: 20px;
                    line-height: 20px;
                    padding-right: 10px;
                    width: 50%;
                    left: 62%;
                    position: absolute;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
                // &:nth-of-type(1) {
                //     background: transparent url("../img/contents/product_under_nav01.jpg") no-repeat scroll left 10px center;
                //     background-size: 80px 80px;
                // }
                // &:nth-of-type(2) {
                //     background: transparent url("../img/contents/product_under_nav02.jpg") no-repeat scroll left 10px center;
                //     background-size: 80px 80px;
                // }
                // &:nth-of-type(3) {
                //     background: transparent url("../img/contents/product_under_nav03.jpg") no-repeat scroll left 10px center;
                //     background-size: 80px 80px;
                // }
                // &:nth-of-type(n+4) {
                //     background: transparent url("../img/contents/product_under_nav04.png") no-repeat scroll left 10px center;
                //     background-size: 80px 80px;
                // }
                /*a {
                        font-size: 15px;
                        line-height: 20px;
                        display: table-cell;
                        vertical-align: middle;
                        width: 235px;
                        height: 100px;
                    }*/
            }
        }
    }
}

@media print,
screen and (max-width: 767px) {
    /* リンク設定 */
    a {
        &:active {
            color: #0067c3;
        }
        &:link {
            color: #0067c3;
        }
        &:visited {
            color: #0067c3;
        }
    }
    /* 基本設定 */
    .disppc {
        display: none; //デフォルトはPC表示なのでSP部分は非表示化
    }
    .innerBasic {
        width: 100%;
    }
    /*Header*/
    #Header {
        position: relative;
        background-color: #000;
        height: 80px;
        #ContBoxHeader {
            padding-left: 0;
            span.catch {
                display: block;
                width: 100%;
                height: 20px;
                background-color: #0c1e35;
                color: #79818d;
                font-family: Open Sans, Arial, sans-serif;
                font-size: 9px;
                line-height: 20px;
                padding-left: 10px;
                letter-spacing: 0.1px;
            }
            h1 {
                position: relative;
                margin-left: 10px;
                margin-top: 11px;
                display:table;
                width: auto;
                height: 38px;
                a {
                    margin-right: 4px;
                    img {
                        width: 60px;
                        height: 23px;
                    }
                }
                span {
                    color: #ffffff;
                    &.logo {
                        display: block;
                        padding-top: 13px;
                        padding-left: 0;
                        font-size: 19px;
                        letter-spacing: -1px;
                    }
                    &.company {
                        position: absolute;
                        top: 0;
                        left: 0;
                        span {
                            display: block;
                            &:nth-of-type(1) {
                                font-size: 10px;
                                letter-spacing: 1.3px;
                            }
                            &:nth-of-type(2) {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
        #MenuBtn {
            position: absolute;
            z-index: 600;
            top: 20px;
            right: 0;
            width: 60px;
            height: 60px;
            padding-top: 12px;
            background: url(../img/header_menu.png) no-repeat center 13px;
            background-size: 26px auto;
            background-color: #343436;
            color: #c0c0c0;
            font-size: 11px;
            text-align: center;
            line-height: 60px;
        }
    }
    /* MenuSp GlobalNavSp */
    #MenuSp {
        display: none;
        width: 100%;
        position: absolute;
        top: 70px;
        z-index: 200;
        #MenuSpMenuList {
            ul {
                li {
                    width: 100%;
                    background: #252528 url("../img/header_icon_close2.png") no-repeat right 18px center;
                    background-size: 16px auto;
                    a {
                        padding-left: 15px;
                        display: block;
                        color: #fff;
                        font-size: 17px;
                        text-decoration: none;
                        height: 60px;
                        line-height: 60px;
                        border-bottom: 1px solid #14141a;
                    }
                    &.parentAccordion {
                        background: #252528 url("../img/header_icon_close.png") no-repeat right 18px center;
                        background-size: 16px auto;
                        ul.accordion {
                            display: none;
                        }
                        &.minus {
                            >a {
                                background: #252528 url("../img/header_icon_open.png") no-repeat right 18px center;
                                background-size: 16px auto;
                            }
                            background: #252528 url("../img/header_icon_open.png") no-repeat right 18px center;
                            background-size: 16px auto;
                        }
                    }
                    &:nth-of-type(1) {
                        /*googleカスタム検索*/
                        .gsc-control-cse{
                            padding:0;
                            background:#343436;
                            border:none;
                        }
                        //background: #343436 url("../img/header_icon01.png") no-repeat right 18px top 23px;
                        //background-size: 18px auto;
                        form {
                            height: 67px;
                            padding:20px 15px 17px 15px;
                            margin:0;
                            position:relative;
                            .gsc-input{
                                width:100%;
                                padding:0;
                            }
                            .gsc-input-box{
                                border:none;
                                background:#343436;
                            }
                            #gs_tti51{
                                padding:0;
                            }
                            .gsib_b{
                                display:none;
                            }
                            input {
                                &.gsc-input{
                                    width:100%;
                                    height:30px!important;
                                    border-bottom:1px solid #525256!important;
                                    background:#343436!important;
                                    text-indent: 0!important;
                                    padding-right:24px!important;
                                    color:#c0c0c0;
                                    font-size:15px;
                                    border-radius:0!important;
                                }
                                &.gsc-search-button{
                                    position:absolute;
                                    top:18px;
                                    right:15px;
                                    width:20px;
                                    height:30px;
                                    margin:0;
                                    border:none;
                                    background: #343436 url("../img/header_icon01.png") no-repeat left center;
                                    background-size: 18px auto;
                                    padding:15px 12px;

                                }
                                //width: 94%;
                                //height: 28px;
                                //margin-right: 15px;
                                //margin-left: 15px;
                                //padding-top: 30px;
                                //padding-right: 45px;
                                //padding-bottom: 18px;
                                //border-bottom: 1px solid #525256;
                                //color: #c0c0c0;
                                //font-size: 15px;
                            }
                        }
                    }
                }
                ul.accordion {
                    li {
                        background: #525257 url("../img/header_icon_close2.png") no-repeat right 21px center;
                        background-size: 11px auto;
                        background-color: #525257;
                        a {
                            font-size: 15px;
                            height: 50px;
                            line-height: 50px;
                            border-bottom: 1px solid #6d6d72;
                            padding-left: 20px;
                        }
                        &:last-of-type {
                            a {
                                border-bottom: none;
                            }
                        }
                    }
                }
            }
            #BtnClose {
                text-align: center;
                span {
                    display: block;
                    padding-top: 54px;
                    background: url(../img/header_menu_close.png) no-repeat scroll center bottom 18px;
                    background-size: 20px auto;
                    color: #c0c0c0;
                    font-size: 11px;
                }
            }
        }
    }
    #MenuBack {
        background-color: #000;
        bottom: 0;
        display: none;
        left: 0;
        opacity: 1;
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 80px;
        z-index: 100;
    }
    /*スマホパン屑*/
    #TopicPath {
        margin: 10px 0;
        width: 100%;
        height: 25px;
        padding: 0 15px;
        ol {
            li {
                display: inline;
                font-size: 11px;
                color: #888;
                a {
                    font-size: 11px;
                    line-height: 25px;
                    text-decoration: underline;
                    color: #239ce7;
                }
                &+li {
                    padding-left: 10px;
                    position: relative;
                    margin-left: 5px;
                }
                &+li:before {
                    content: "";
                    display: inline-block;
                    position: absolute;
                    top: 4px;
                    left: 0;
                    width: 4px;
                    height: 6px;
                    background: url("../img/icon_arrow_li.png") no-repeat left center;
                    background-size: 4px 6px;
                    vertical-align: middle;
                }
            }
        }
    }
    #MainImg {
        width: 100%;
        div#MainImgInner {
            margin: 0 auto;
            width: 100%;
            background: url("../img/main_pattern.png") repeat scroll left top;
            height: 200px;
            h2 {
                padding-top: 66px;
                text-align: center;
                span:nth-of-type(1) {
                    color: #fff;
                    font-size: 37px;
                }
                span:nth-of-type(2) {
                    display: block;
                    padding-top: 11px;
                    color: #cae2ff;
                    font-size: 17px;
                    letter-spacing: 6px;
                }
            }
        }
    }
    /*Main*/
    #Main {
        section#ContBox01 {
            min-height: 100px;
        }
    }
    /* 見出し */
    h3,
    h4.h3 {
        width: 100%;
        padding-top: 17px;
        padding-bottom: 17px;
        padding-left: 10px;
        letter-spacing: 2px;
        background: url(../img/h3_bg.jpg) repeat left center;
        color: #fff;
        font-size: 23px;
        margin-bottom: 23px;
        line-height: 27px;
    }
    /*aside.sub*/
    aside.sub {
        width: 100%;
        margin-top: 10px;
        h3 {
            height: 40px;
            padding-left: 10px;
            line-height: 40px;
            font-size: 14px;
            background: $color_h3_basic;
            color: #fff;
            text-align: center;
        }
        ul {
            li {
                a {
                    display: block;
                    padding-left: 10px;
                    line-height: 40px;
                    width: 100%;
                    height: 40px;
                    color: #333;
                }
            }
        }
    }
    /*アコーディオン見出し*/
    #PageProduct.pageEntry #Container #Main #ContBox01 {
        div.accordion {
            display: none;
        }
        h5 {
            background: url("../img/contents/product_nav_open.png") no-repeat right 5% center;
            background-size: 16px 16px;
            padding: 25px 50px 25px 15px;
            border-top: 1px solid #e3e3e3;
            color: #0067c3;
            font-size: 19px;
            line-height: 30px;
        }
        .parentAccordion.minus {
            h5 {
                background: url("../img/contents/product_nav_close.png") no-repeat right 5% center;
                background-size: 16px 16px;
                color: #000;
                padding-bottom: 15px;
            }
        }
    }
    /*#Footer上Contact  レイアウト*/
    #FooterContact {
        width: 100%;
        height: 330px;
        background: url("../img/footer_bg_sp.jpg") no-repeat center center;
        background-size: cover;
        .footerCopy {
            padding-top: 44px;
            font-size: 20px;
            color: #fff;
            text-align: center;
            width: 100%;
            margin-bottom: 25px;
            line-height: 30px;
            padding-left: 14px;
            letter-spacing: 1px;
            &:after {
                content: "";
                display: block;
                width: 142px;
                margin: 13px auto;
                border-bottom: 2px solid #6a7683;
            }
        }
        .contactSet {
            margin-left: auto;
            margin-right: auto;
            width: 280px;
            .tel {
                color: #fff;
                width: 100%;
                margin-bottom: 10px;
                a {
                    color: #fff;
                }
                span:nth-of-type(1) {
                    font-size: 18px;
                    width: 46px;
                    display: inline-block;
                }
                span:nth-of-type(2) {
                    font-size: 33px;
                    font-family: Open Sans, Arial, sans-serif;
                    letter-spacing: 2px;
                    width: 234px;
                    display: inline-block;
                    text-decoration: underline;
                    margin-bottom: 5px;
                }
                span:nth-of-type(3) {
                    font-size: 13px;
                    display: block;
                    letter-spacing: 1px;
                    text-align: center;
                }
            }
            .mail {
                width: 100%;
                a {
                    background: #0b64d0 url("../img/footer_icon01.png") no-repeat scroll 12% center;
                    background-size: 25px auto;
                    color: #fff;
                    display: block;
                    font-size: 18px;
                    height: 60px;
                    line-height: 60px;
                    width: 100%;
                    padding-left: 25%;
                    text-decoration: none;
                    border: 1px solid #4d8bd8;
                }
            }
        }
    }
    /*Footer*/
    #Footer {
        position: relative;
        width: 100%;
        background-color: #242527;
        color: #aaa;
        font-size: 13px;
        padding: 50px 15px 125px;
        #FooterInfo {
            margin-bottom: 22px;
            dt {
                margin-bottom: 11px;
                height: 32px;
                line-height: 33px;
                color: #fff;
                font-size: 19px;
                a img {
                    margin-right: 12px;
                    &:hover {
                        opacity: 1.0 !important;
                    }
                }
            }
            dd {
                line-height: 24px;
                letter-spacing: 1.4px;
                a {
                    color: #aaa;
                    text-decoration: none;
                    margin-right: 10px;
                }
            }
        }
        #FooterNav {
            ul {
                li {
                    display: inline-block;
                    width: 49%;
                    margin-bottom: 22px;
                    a {
                        font-size: 13px;
                        color: #fff;
                    }
                }
            }
        }
        #Copyright {
            margin-top: 10px;
            font-family: 'Open Sans', sans-serif;
            font-size: 10px;
            color: #aaaaaa;
            letter-spacing: 0.7px;
        }
    }
    /*sp  #PageTop  */
    #PageTop {
        position: absolute;
        right: 13px;
        bottom: 94px;
        text-indent: -9999px;
        a:link,
        a:visited {
            display: block;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background: url("../img/pageTop.png") no-repeat center center;
            background-size: 100% 100%;
        }
    }
    /*sp  #Bottom_nav  */
    #DivBottom_nav {
        height: 70px;
        bottom: 0;
        display: table;
        left: 0;
        position: fixed;
        width: 100%;
        z-index: 3;
        #Bottom_nav {
            ul {
                height: 70px;
                line-height: 70px;
                background-color: #343436;
                li {
                    display: inline-block;
                    width: 24%;
                    height: 70px;
                    text-align: center;
                    line-height: 70px;
                    a {
                        display: block;
                        height: 70px;
                        padding-top: 18px;
                        font-size: 11px;
                        color: #c0c0c0;
                        text-decoration: none;
                    }
                    &:nth-of-type(1) {
                        background: url("../img/contents/under_nav_icon01.png") no-repeat center top 14px;
                        background-size: 27px auto;
                    }
                    &:nth-of-type(2) {
                        background: url("../img/contents/under_nav_icon02.png") no-repeat center top 14px;
                        background-size: 25px auto;
                    }
                    &:nth-of-type(3) {
                        background: url("../img/contents/under_nav_icon03.png") no-repeat center top 14px;
                        background-size: 22px auto;
                    }
                    &:nth-of-type(4) {
                        background: url("../img/contents/under_nav_icon04.png") no-repeat center top 14px;
                        background-size: 24px auto;
                    }
                }
            }
        }
    }
    #DivProduct_under_nav {
        ul {
            width: 100%;
            padding-right: 15px;
            padding-left: 15px;
            li {
                position: relative;
                width: 100%;
                //height: 71px;
                padding: 5px;
                margin-bottom: 9px;
                border: 1px solid #cfd7e8;
                display: table;
                &:nth-of-type(4n) {
                    margin-right: 0;
                }
                /*img {
                        float: left;
                        width: 60px;
                        height: 60px;
                        margin-left: 5px;
                    }
                    img+p {
                        float: left;
                        line-height: 20px;
                        left: 152px;
                        position: absolute;
                        top: 50%;
                        transform: translate(-50%, -50%);
                    }
                    a {
                        font-size: 15px;
                        line-height: 20px;
                        display: table-cell;
                        vertical-align: middle;
                        width: 100%;
                        height: 71px;
                        text-decoration: none;
                    }*/
                span {
                    display: table-cell;
                    width: 60px;
                    height: 60px;
                    vertical-align: middle;
                    img {
                        max-width: 100%;
                        max-height: 100%;
                        width: auto;
                        height: auto;
                    }
                }
                span+p {
                    display: table-cell;
                    vertical-align: middle;
                    padding-left: 20px;
                    line-height: 20px;
                    a {
                        font-size: 15px;
                        line-height: 20px;
                        vertical-align: middle;
                        text-decoration:none;
                    }
                }
            }
        }
    }
}
